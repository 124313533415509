import handleError from 'helpers/error-handler-helper'
import { handlePageable } from 'helpers/pageable-handler-helper'
import api from 'libs/api'
import { MarketProductCategoryType } from 'types/market-product-category-type'
import { Page } from 'types/page-type'
import { PageableRequest } from 'types/pageable-request-type'

export const QUERY_KEY = '/market-product-categories-public'

export type ParamsType = PageableRequest
export type ResponseType = Page<MarketProductCategoryType>
type GetTeachersServiceType = (params: ParamsType) => Promise<ResponseType>

const getAllMarketProductCategoriesService: GetTeachersServiceType = async (
  params
) => {
  try {
    const response = await api.get<Page<MarketProductCategoryType>>(
      '/market-product-categories-public',
      { params }
    )
    const responseData = response.data
    const result = handlePageable({ responseData, params })
    return result
  } catch (error) {
    const defaultMessage = 'Erro ao recuperar categorias'
    throw handleError({ error, defaultMessage })
  }
}

export default getAllMarketProductCategoriesService
