import HomeScreen, {
  homeRouteBannerInitialParams,
  homeRouteMarketProductCategoriesInitialParams,
  homeRouteMarketProductsInitialParams,
} from 'components/screens/home/home-screen'
import { GetStaticProps, NextPage } from 'next'
import { QueryClient, dehydrate } from 'react-query'
import getAllBannersService, {
  QUERY_KEY as BANNERS_QUERY_KEY,
} from 'services/banner/get-all-banners-service'
import getAllMarketProductCategoriesService, {
  QUERY_KEY as MARKET_PRODUCTS_CATEGORIES_QUERY_KEY,
} from 'services/market-product-category/get-all-market-product-categories-service'
import getAllMarketProductsService, {
  QUERY_KEY as MARKET_PRODUCTS_QUERY_KEY,
} from 'services/market-product/get-all-market-products-service'

export interface HomeRouteProps {}
export const HomeRoute: NextPage = (): JSX.Element => {
  return <HomeScreen />
}

export default HomeRoute

export const getStaticProps: GetStaticProps = async () => {
  const queryClient = new QueryClient()

  await queryClient.prefetchQuery(
    [MARKET_PRODUCTS_QUERY_KEY, homeRouteMarketProductsInitialParams],
    () => getAllMarketProductsService(homeRouteMarketProductsInitialParams)
  )

  await queryClient.prefetchQuery(
    [BANNERS_QUERY_KEY, homeRouteBannerInitialParams],
    () => getAllBannersService(homeRouteBannerInitialParams)
  )

  await queryClient.prefetchQuery(
    [
      MARKET_PRODUCTS_CATEGORIES_QUERY_KEY,
      homeRouteMarketProductCategoriesInitialParams,
    ],
    () =>
      getAllMarketProductCategoriesService(
        homeRouteMarketProductCategoriesInitialParams
      )
  )

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: parseInt(process.env.NEXT_PUBLIC_ISR_REVALIDATE_TIME),
  }
}
