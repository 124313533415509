import { useQuery } from 'react-query'
import getAllMarketProductsService, {
  ParamsType,
  QUERY_KEY,
  ResponseType,
} from 'services/market-product/get-all-market-products-service'
import { QueryHookType } from 'types/query-hook-type'

type HookType = QueryHookType<ParamsType, ResponseType>
export const useGetMarketProducts: HookType = (params, options) => {
  const { data, error, isLoading, refetch } = useQuery(
    [QUERY_KEY, params],
    () => getAllMarketProductsService(params),
    { ...options }
  )
  return { data, error, isLoading, refetch }
}
