import { useQuery } from 'react-query'
import getAllBannersService, {
  ParamsType,
  QUERY_KEY,
  ResponseType,
} from 'services/banner/get-all-banners-service'
import { QueryHookType } from 'types/query-hook-type'

type HookType = QueryHookType<ParamsType, ResponseType>
export const useGetAllBanners: HookType = (params, options) => {
  const { data, error, isLoading, refetch } = useQuery(
    [QUERY_KEY, params],
    () => getAllBannersService(params),
    { ...options }
  )
  return { data, error, isLoading, refetch }
}
