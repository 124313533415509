import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import Box from 'components/atoms/box'
import Circle from 'components/atoms/circle'
import Column from 'components/atoms/column'
import Row from 'components/atoms/row'
import Text from 'components/atoms/text'
import { classNameBuilder } from 'helpers/class-name-builder'
import { useQueryParams } from 'hooks/useQueryParams'
import { useRouter } from 'next/router'
import React from 'react'
import { MarketProductCategoryType } from 'types/market-product-category-type'

export interface CategoryListProps {
  categories?: MarketProductCategoryType[]
  pending?: boolean
  className?: string
}

const CategoryList: React.FC<CategoryListProps> = ({
  categories = [],
  className,
}) => {
  const { query } = useRouter()
  const { updateQuery } = useQueryParams()
  const queryCategories = query?.categories || []
  const selectedCategories = React.useMemo(
    () =>
      typeof queryCategories === 'string' ? [queryCategories] : queryCategories,
    [queryCategories]
  )
  const categoryListRef = React.useRef<HTMLDivElement>(null)
  const handleLeftArrowClick = () => {
    categoryListRef.current.scrollLeft += categoryListRef.current.offsetWidth
  }
  const handleRightArrowClick = () => {
    categoryListRef.current.scrollLeft -= categoryListRef.current.offsetWidth
  }

  return (
    <Row
      className={classNameBuilder(
        'container relative z-0 mx-auto px-sm sm:px-md md:px-lg lg:px-xl',
        className
      )}
    >
      <Box className="absolute left-0 top-0 bottom-0 z-20 flex items-center justify-start">
        <Circle
          className="h-16 w-16 cursor-pointer bg-gray-200 bg-opacity-10 hover:bg-gray-400 hover:bg-opacity-30 hover:shadow-sm"
          onClick={handleRightArrowClick}
        >
          <ChevronLeftIcon className="h-lg w-lg text-gray-600" />
        </Circle>
      </Box>
      <Row
        ref={categoryListRef}
        className="z-10 snap-x scroll-p-2 space-x-sm overflow-scroll overscroll-contain scroll-smooth px-2 py-sm scrollbar-hide sm:space-x-[20px]"
      >
        {categories.map((category) => {
          const isActive = selectedCategories.includes(category._id)
          const onCardClick = () => {
            updateQuery({ categories: isActive ? null : category._id })
          }
          return (
            <CategoryCard
              key={category._id}
              className="snap-start"
              category={category}
              isActive={isActive}
              onClick={onCardClick}
            />
          )
        })}
      </Row>
      <Box className="absolute right-0 top-0 bottom-0 z-20 flex items-center justify-end">
        <Circle
          className="h-16 w-16 cursor-pointer bg-gray-200 bg-opacity-10 hover:bg-gray-400 hover:bg-opacity-30 hover:shadow-sm"
          onClick={handleLeftArrowClick}
        >
          <ChevronRightIcon className="h-lg w-lg text-gray-600" />
        </Circle>
      </Box>
    </Row>
  )
}

export default CategoryList

interface CategoryCardProps {
  category: MarketProductCategoryType
  isActive?: boolean
  className?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
}
const CategoryCard: React.FC<CategoryCardProps> = ({
  isActive,
  category,
  className,
  onClick,
}) => {
  const filePath = process.env.NEXT_PUBLIC_IMAGE_STORAGE_URL
  const fullPath = `${filePath}/${category.iconUrl}`

  return (
    <Column
      className={classNameBuilder(
        'group h-full max-h-36 w-48 flex-shrink-0 cursor-pointer rounded-lg p-[18px] sm:max-h-44 sm:w-56 sm:px-md sm:py-lg ',
        isActive
          ? 'bg-gradient-to-bl from-indigo-400 to-indigo-700 text-white'
          : 'bg-white text-indigo-500 drop-shadow-md',
        className
      )}
      onClick={onClick}
    >
      <Circle
        className={classNameBuilder(
          'h-12 w-12 transition ease-in-out sm:h-16 sm:w-16',
          isActive ? 'bg-indigo-50' : 'bg-indigo-50'
        )}
      >
        <img
          src={fullPath}
          className="flex h-md w-md flex-shrink-0 sm:h-lg sm:w-lg"
        />
      </Circle>
      <Text className="mt-md truncate text-sm font-bold sm:mt-sm">
        {category.name}
      </Text>
    </Column>
  )
}
