import handleError from 'helpers/error-handler-helper'
import { handlePageable } from 'helpers/pageable-handler-helper'
import api from 'libs/api'
import { MarketProductType } from 'types/market-product-type'
import { Page } from 'types/page-type'
import { PageableRequest } from 'types/pageable-request-type'

export const QUERY_KEY = '/market-products-public'

export type ParamsType = {
  categories?: string[]
  orderBy?: string
} & PageableRequest
export type ResponseType = Page<MarketProductType>
type ServiceType = (params: ParamsType) => Promise<ResponseType>

const getAllMarketProductsService: ServiceType = async (params) => {
  try {
    const response = await api.get<Page<MarketProductType>>(
      '/market-products-public',
      { params }
    )
    const responseData = response.data
    const result = handlePageable({ responseData, params })
    return result
  } catch (error) {
    const defaultMessage = 'Erro ao recuperar produtos'
    throw handleError({ error, defaultMessage })
  }
}

export default getAllMarketProductsService
