import Text from 'components/atoms/text'
import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'
import { MarketProductType } from 'types/market-product-type'
import ProductCard from '../../organisms/product-card'

export interface ProductsGridProps {
  marketProducts?: MarketProductType[]
  pending?: boolean
  className?: string
}
const ProductsGrid: React.FC<ProductsGridProps> = ({
  marketProducts = [],
  className,
  pending,
}) => {
  return (
    <div
      className={classNameBuilder(
        'grid grid-cols-1 gap-md sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4',
        className
      )}
    >
      {pending ? (
        <>
          <Text>Carregando produtos...</Text>
        </>
      ) : (
        <>
          {marketProducts.map((marketProduct) => (
            <ProductCard
              key={marketProduct._id}
              marketProduct={marketProduct}
            />
          ))}
        </>
      )}
    </div>
  )
}

export default ProductsGrid
