import { PhotoIcon } from '@heroicons/react/24/solid'
import Image from 'components/atoms/image'
import { classNameBuilder } from 'helpers/class-name-builder'

type ProductImageProps = {
  src?: string
  alt?: string
}
const ProductImage: React.FC<ProductImageProps> = ({ src, alt }) => {
  if (!src) {
    return <PhotoIcon className="h-lg w-lg text-white opacity-60" />
  }
  return (
    <Image
      className={classNameBuilder(
        'h-full w-full border-b border-indigo-50 object-cover'
      )}
      src={src}
      alt={alt}
    />
  )
}

export default ProductImage
