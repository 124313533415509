/* eslint-disable @typescript-eslint/ban-types */
import { Page } from 'types/page-type'
import { PageableRequest } from 'types/pageable-request-type'
import { PageableResponse } from 'types/pageable-response-type'

export type ParamsType<TResponseData, TResult = TResponseData> = {
  params: PageableRequest
  responseData: PageableResponse<TResponseData>
  converter?: (param: TResponseData) => TResult
}

export const handlePageable = <TResponseData, TResult = TResponseData>({
  responseData,
  params,
  converter = (data) => data as unknown as TResult,
}: ParamsType<TResponseData, TResult>): Page<TResult> => {
  const result = {
    data: responseData.data.map(converter),
    total: responseData.total,
    size: params.size,
    page: params.page,
    totalPages: Math.ceil(responseData.total / params.size),
  }
  return result
}
