import React from 'react'

export interface CurrencyFormatterProps {
  children?: string | number
}

export const BRLFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})

const CurrencyFormatter: React.FC<CurrencyFormatterProps> = ({
  children = 0,
}) => {
  let value: any = Number(children)
  if (Number.isNaN(value)) return null

  try {
    value = BRLFormatter.format(value)
  } catch (error) {
    value = children
    console.error(error)
  }
  return <React.Fragment>{value}</React.Fragment>
}

export default CurrencyFormatter
