import { useGetAllBanners } from 'api-hooks/banner/use-get-all-banners'
import { useGetAllMarketProductCategories } from 'api-hooks/market-product-category/use-get-all-market-product-categories'
import { useGetMarketProducts } from 'api-hooks/market-product/use-get-all-market-products'
import Container from 'components/atoms/container'
import Heading from 'components/atoms/heading'
import NavBar from 'components/molecules/nav-bar'
import Body from 'components/organisms/body'
import Footer from 'components/organisms/footer'
import Header from 'components/organisms/header'
import Webpage from 'components/organisms/webpage'
import { useRouter } from 'next/router'
import React from 'react'
import BannerCarousel from './banner-carousel'
import CategoryList from './category-list'
import ProductsGrid from './products-grid'
import { OrderDirection } from 'types/pageable-request-type'

export const homeRouteBannerInitialParams = { page: 1, size: 100 }
export const homeRouteMarketProductCategoriesInitialParams = {
  page: 1,
  size: 100,
}
export const homeRouteMarketProductsInitialParams = {
  page: 1,
  size: 12,
  categories: [],
  orderBy: 'emphasisOrder',
  orderDirection: 'asc' as OrderDirection,
}
export interface HomeScreenProps {}
const HomeScreen: React.FC<HomeScreenProps> = () => {
  const { query } = useRouter()
  const queryCategories = query?.categories || []
  const selectedCategories = React.useMemo(
    () =>
      typeof queryCategories === 'string' ? [queryCategories] : queryCategories,
    [queryCategories]
  )

  const { data: pageable, isLoading } = useGetMarketProducts({
    ...homeRouteMarketProductsInitialParams,
    categories: selectedCategories,
    size: selectedCategories.length
      ? 50
      : homeRouteMarketProductsInitialParams.size,
  })
  const marketProducts = pageable?.data

  const { data: bannerPage } = useGetAllBanners(homeRouteBannerInitialParams)
  const banners = React.useMemo(
    () =>
      bannerPage?.data.filter(
        (banner) => banner.desktopImageUrl && banner.mobileImageUrl
      ),
    [marketProducts]
  )

  const { data: categoryPage, isLoading: loadingCategories } =
    useGetAllMarketProductCategories(
      homeRouteMarketProductCategoriesInitialParams
    )
  const categories = categoryPage?.data

  return (
    <Webpage title="Home | E-commerce Superus">
      <Header />
      <NavBar active="home" />
      <Body className="overflow-x-hidden">
        <BannerCarousel banners={banners} />
        <Container className="py-md">
          <Heading className="text-lg font-bold text-black">
            Escolha por categoria
          </Heading>
        </Container>
        <CategoryList
          className=""
          categories={categories}
          pending={loadingCategories}
        />
        <Container className="py-md">
          <Heading className="mt-lg text-lg font-bold text-black">
            Últimos Cursos
          </Heading>
          <ProductsGrid
            className="mt-md"
            marketProducts={marketProducts}
            pending={isLoading}
          />
        </Container>
      </Body>
      <Footer />
    </Webpage>
  )
}

export default HomeScreen
