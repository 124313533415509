import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'
import Box from './box'

export type SeparatorSizeType = 'text' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export interface SeparatorProps {
  size?: SeparatorSizeType
}

const Separator: React.FC<SeparatorProps> = ({ size = 'md' }) => {
  return (
    <Box className={classNameBuilder('flex shrink-0', sizeClassMap[size])} />
  )
}

export default Separator

const sizeClassMap: Record<SeparatorSizeType, string> = {
  text: 'w-2 h-2',
  xs: 'w-xs h-xs',
  sm: 'w-sm h-sm',
  md: 'w-md h-md',
  lg: 'w-lg h-lg',
  xl: 'w-xl h-xl',
}
