import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import Circle from 'components/atoms/circle'
import Image from 'components/atoms/image'
import Row from 'components/atoms/row'
import { classNameBuilder } from 'helpers/class-name-builder'
import { useRouter } from 'next/router'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { BannerType } from 'types/banner-type'

export interface BannerCarouselProps {
  // isLoading?: boolean
  banners?: BannerType[]
}

const BannerCarousel: React.FC<BannerCarouselProps> = ({ banners = [] }) => {
  return (
    <>
      <Row className="sm:hidden ">
        <CustomCarousel className="">
          {banners.map((banner) => (
            <BannerSlide
              key={`mobile-${banner._id}`}
              banner={banner}
              variant="mobile"
            />
          ))}
        </CustomCarousel>
      </Row>
      {/* improve to not duplicate code */}
      <Row className="hidden sm:flex ">
        <CustomCarousel>
          {banners.map((banner) => (
            <BannerSlide key={banner._id} banner={banner} />
          ))}
        </CustomCarousel>
      </Row>
    </>
  )
}

export default BannerCarousel

interface BannerSlideProps {
  banner?: BannerType
  variant?: 'desktop' | 'mobile'
}
const BannerSlide: React.FC<BannerSlideProps> = ({
  banner,
  variant = 'desktop',
}) => {
  if (!banner) return null
  const src =
    variant === 'mobile' ? banner.mobileImageUrl : banner.desktopImageUrl
  const router = useRouter()
  const handlerOnClick = () => {
    if (banner.link) {
      router.push(banner.link)
    }
  }
  return (
    <Row
      className={classNameBuilder(
        'items-center justify-center',
        banner.link && 'cursor-pointer'
      )}
      onClick={handlerOnClick}
    >
      <Image src={src} alt={banner.name} />
    </Row>
  )
}

interface CustomCarouselProps {
  className?: string
  children: React.ReactChild[]
  onClickItem?: (index: number, item: React.ReactNode) => void
}
const CustomCarousel: React.FC<CustomCarouselProps> = ({
  children,
  className,
  onClickItem,
}) => {
  return (
    <Carousel
      key={children.length}
      className={className}
      showStatus={false}
      onClickItem={onClickItem}
      infiniteLoop
      autoPlay={true}
      stopOnHover
      showThumbs={false}
      interval={7000}
      dynamicHeight={true}
      renderArrowPrev={(onClickHandler, hasPrev) =>
        hasPrev && (
          <Circle
            className="absolute top-1/2 left-10 z-10 -mt-lg hidden h-16 w-16 cursor-pointer bg-white bg-opacity-10 hover:bg-opacity-30 hover:shadow-sm sm:flex"
            onClick={onClickHandler}
          >
            <ChevronLeftIcon className="h-lg w-lg text-white" />
          </Circle>
        )
      }
      renderArrowNext={(onClickHandler, hasNext) =>
        hasNext && (
          <Circle
            className="absolute top-1/2 right-10 z-10 -mt-lg hidden h-16 w-16 cursor-pointer bg-white bg-opacity-10 hover:bg-opacity-30 hover:shadow-sm sm:flex"
            onClick={onClickHandler}
          >
            <ChevronRightIcon className="h-lg w-lg text-white" />
          </Circle>
        )
      }
    >
      {children}
    </Carousel>
  )
}

//http://react-responsive-carousel.js.org/storybook/?path=/story/01-basic--base
