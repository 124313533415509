import Column from 'components/atoms/column'
import CurrencyFormatter from 'components/atoms/currency-formatter'
import Row from 'components/atoms/row'
import Separator from 'components/atoms/separator'
import Text from 'components/atoms/text'
import ProductImage from 'components/molecules/product-image'
import { classNameBuilder } from 'helpers/class-name-builder'
import Link from 'next/link'
import { MarketProductType } from 'types/market-product-type'

export interface ProductCardProps {
  marketProduct: MarketProductType
}
const ProductCard: React.FC<ProductCardProps> = ({ marketProduct }) => {
  const productId = marketProduct._id
  const discount =
    ((marketProduct.price - marketProduct.priceWithDiscount) /
      marketProduct.price) *
      100 || null
  const tag = !discount ? '' : `até ${Math.floor(discount)}% off`
  const hasDiscount = marketProduct.price !== marketProduct.priceWithDiscount
  return (
    <Link href={`/produtos/${productId}`}>
      <Column className="h-[410px] w-full cursor-pointer overflow-hidden rounded-md drop-shadow-sm transition ease-in-out hover:-translate-y-1 hover:drop-shadow-lg">
        <Row className="relative h-[216px] items-center justify-center bg-gradient-to-br from-red-500 to-indigo-700">
          <ProductImage src={marketProduct.imageUrl} />
          {/* TODO: get this from api */}
          <Row
            className={classNameBuilder(
              'absolute top-4 right-4 h-lg items-center rounded-full bg-yellow-400 px-sm text-[10px] font-bold uppercase text-indigo-600',
              !tag && 'hidden'
            )}
          >
            {tag}
          </Row>
        </Row>
        <Column className="flex-1 space-y-xs bg-white px-md pt-sm pb-md">
          <Text className="h-full max-h-14 justify-center text-lg font-bold text-indigo-500 line-clamp-2">
            {marketProduct?.name}
          </Text>
          {hasDiscount && (
            <Text className="text-sm text-gray-400 line-through">
              de <CurrencyFormatter>{marketProduct.price}</CurrencyFormatter>
            </Text>
          )}
          {!hasDiscount && (
            <Text className="text-sm text-gray-400">a partir de</Text>
          )}
          <Text className="text-md text-gray-500">
            {hasDiscount && 'por '}{' '}
            <CurrencyFormatter>
              {marketProduct.priceWithDiscount}
            </CurrencyFormatter>
          </Text>
          <Row className="items-end">
            {marketProduct?.maxInstallments > 1 ? (
              <>
                <Text className="text-md font-normal leading-none text-gray-500">
                  {marketProduct.maxInstallments}x de{' '}
                </Text>
                <Separator size="text" />
                <Text className=" text-2xl font-bold leading-none text-green-500">
                  <CurrencyFormatter>
                    {marketProduct.priceWithDiscount /
                      marketProduct.maxInstallments}
                  </CurrencyFormatter>{' '}
                </Text>
                <Separator size="text" />
                <Text className=" text-md font-normal leading-none text-gray-500">
                  sem juros
                </Text>
              </>
            ) : (
              <Text className="text-2xl font-bold leading-none text-green-500">
                à vista
              </Text>
            )}
          </Row>
        </Column>
      </Column>
    </Link>
  )
}

export default ProductCard
