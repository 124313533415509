import handleError from 'helpers/error-handler-helper'
import { handlePageable } from 'helpers/pageable-handler-helper'
import api from 'libs/api'
import { BannerType } from 'types/banner-type'
import { CategoryType } from 'types/category-type'
import { Page } from 'types/page-type'
import { PageableRequest } from 'types/pageable-request-type'
import { PageableResponse } from 'types/pageable-response-type'

export const QUERY_KEY = '/banners-public'

export type ParamsType = {
  categories?: CategoryType
  orderBy?: string
} & PageableRequest
export type ResponseType = Page<BannerType>
type ServiceType = (params: ParamsType) => Promise<ResponseType>

const getAllBannersService: ServiceType = async (params) => {
  try {
    const response = await api.get<PageableResponse<BannerType>>(
      '/banners-public',
      { params }
    )
    const responseData = response.data
    const result = handlePageable({ responseData, params })
    return result
  } catch (error) {
    const defaultMessage = 'Erro ao recuperar banners'
    throw handleError({ error, defaultMessage })
  }
}

export default getAllBannersService
